// import { Controller } from "stimulus"
// import "../../lib/ckeditor"

// export default class extends Controller {
//   static targets = [ "input" ]

//   static toolbar_simple = [
//   	'bold',
//   	'italic',
//   	'strikethrough',
//   	'code',
//   	'link',
//   	'|',
//   	'bulletedList',
//   	'numberedList',
//   	'|',
//   	'removeFormat',
//   	'undo',
//   	'redo'
//   ]

//   static toolbar_odf = [
//     'bold',
//   	'italic',
//   	'underline',
//   	'|',
//   	'blockQuote',
//   	'|',
//   	'removeFormat',
//   	'undo',
//   	'redo'
//   ]

//   connect() {
//     this.setupEditor()
//   }

//   setupEditor(initialData) {
//     var editor_options = {
//       toolbar: {
//         items: toolbar,
//         viewportTopOffset: 107
//       }
//     }

//     if (!this.data.has('toolbar') || this.data.get('toolbar') == 'odf') {
//       editor_options.toolbar.items = this.constructor.toolbar_odf
//     } else {
//       editor_options.toolbar.items = this.constructor.toolbar_simple
//     }

//     ClassicEditor
//       .create( this.inputTarget, editor_options )
//       .then( editor => {
//           // console.log( editor );
//       } )
//       .catch( error => {
//           console.error( error );
//       } )
//   }

// }
