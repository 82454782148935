import "jquery-mask-plugin";
import "../lib/jquery.maskMoney";

export default () => {

  $("[data-mask]").each(function() {
    $(this).mask(this.getAttribute("data-mask"), { autoclear: false });
  });

  $(".currency").maskMoney();

}
