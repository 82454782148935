import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "select2", "spinner" ]

  connect() {
    this.inputTargets.forEach(t => {
      t.addEventListener('change', e => this.submit())
    })

    this.select2Targets.forEach(t => {
      $(t).on('select2:select', e => this.submit())
      $(t).on('select2:clear', e => this.submit())
    })

  }

  submit() {
    this.showSpinner()
    this.element.submit()
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      $(this.spinnerTarget).removeClass('d-none')
    }
  }

}
