import './modals'
import './flashMessages'

import initMasks    from './masks'
import initDate     from './datePicker'
import clearForm    from './clearForm'
import select2Setup from './select2Setup'

export const initApp = () => {
  select2Setup();
  initMasks();
  initDate();
  clearForm();

  $('[data-toggle="tooltip"]').tooltip();
  
}

// $.ajaxSetup({headers: { 'X-CSRF-Token': Rails.csrfToken() }})

document.addEventListener("turbolinks:load", () => initApp());
