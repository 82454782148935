import qq from "../../lib/fileuploader";

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  connect() {

    const uploader = new qq.FileUploaderBasic({
      button: (this.hasButtonTarget ? this.buttonTarget : this.element),
      action: this.data.get("url"),
      multiple: false,
      debug: false,
      method: this.httpMetohd,
      params: {
        authenticity_token: $("meta[name='csrf-token']").attr("content")
      },
      onSubmit: this.onSubmit,
      onComplete: this.onComplete
    })

  }

  onSubmit() {
    console.log('implementar onSubmit na subclasse')
  }

  onComplete(id, fileName, response) {
    console.log('implementar onComplete na subclasse')
    // $('#arquivos_pedido').load('<%= pedido_arquivos_path(@pedido) %>');
  }

  get httpMetohd() { return 'POST' }

}


// Usar subclasse desse controller para tratar a UI especificamente para cada caso
//
// ex: controllers/pedido/arquivos_controller.js
//
// import UploaderController from "../uploader_controller";
//
// export default class extends UploaderController {
//
//   onSubmit() {
//     // console.log('implementacao complexa de onSubmit')
//   }
//
//   onComplete(id, fileName, json)  {
//     $('#arquivos_pedido').html(json.html)
//   }
//
// }
//
// ---------------------------------------------
// na view:
//
//   <%= button_tag icon(:plus, 'Adicionar Arquivo'),
//                    class: 'btn btn-success',
//                    data: {
//                      controller: 'pedido--arquivos',
//                      'pedido--arquivos-url': pedido_arquivos_path(@pedido)
//                    } %>
